import { getStateValueType, getStateValueTypeResults } from './selectors.types';
import { getCostsMap } from './overview';
import sum from 'lodash.sum';
import merge from 'lodash.merge';
import { RatesInfo } from '@zappy-ride/library.calculations';
import { useFormContext } from 'react-hook-form';
import { useHasTabs } from '../../../hooks/useHasTabs';

export const summary = (
  getStateValue: getStateValueType
): getStateValueTypeResults => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setValue, getValues } = useFormContext();
  const { assumptionsData } = getValues();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasTab = useHasTabs();


  // We can pull data from calculations state passing the full path of the value we want
  const [annualFuelSavings = 0, electricRatesInfo] = getStateValue({
    path: 'total.annual.fuel',
    lookupKey: ['savings.total', 'electricRatesInfo'],
  }) as [number, RatesInfo[]];

  // we also can pull more than one value in the same call, we just have to pass the fields as first parameter into an array
  // and pass what the path of parent as the second parameter
  const [annualMiles, annualHours, vehicleCount] = getStateValue({
    lookupKey: ['miles', 'hours', 'count'],
    path: 'total.annual.vehicles',
  }) as [number, number, number];

  const rates =
    electricRatesInfo &&
    electricRatesInfo.find((i) => i.rate?.name !== undefined)
      ? electricRatesInfo
      : [];

  if (rates && rates.length > 0) {
    const simpleRate = getValues('simpleRate');

    const enhancedRates = {
      [simpleRate.name]: simpleRate,
    };

    merge(
      enhancedRates,
      rates.reduce<any>((acc, i) => {
        acc[i.rate.name] = i.rate;
        return acc;
      }, {})
    );

    const selectedRateId = getValues('assumptionsData.selectedRateId');

    const selectedRate = enhancedRates[selectedRateId] || rates[0].rate;

    setValue('assumptionsData.selectedRateId', selectedRate.name);
    setValue('assumptionsData.selectedRateName', selectedRate.longName);

    setValue('assumptionsData.rates', enhancedRates);
  }
  const isOffRoad = getValues('vehicleSets[0].input.is_offroad') || false;

  // here we cal pull all status from states we are using, or we can pull each status when pulling data from each state, like:
  /*
    const [fuelStatus, annualFuelSavings] = <[boolean, number]>getStateValue("status.fuel.isReady", "total.annual.fuel.savings.total");
  */
  const [
    fuelStatus,
    rateEngineStatus,
    vehiclesStatus,
    calculationsStatus,
    inputsStatus,
  ] = getStateValue({
    lookupKey: [
      'fuel.isReady',
      'rateEngine.isReady',
      'vehicles.isReady',
      'calculations.isReady',
      'inputs.isReady',
    ],
    path: 'status',
  }) as [boolean, boolean, boolean, boolean, boolean];

  // have to get from cumulative cause have no annual
  const [totalIncentives = 0, totalEmissions = 0] = getStateValue({
    lookupKey: [
      hasTab('lcfs') ? 'incentives.total' : 'incentives.others',
      'emissions.emissionsReduced'
    ],
    path: 'total.cumulative[-1]',
  }) as [number, number];

  const cumulative = getStateValue({
    lookupKey: 'total.cumulative',
    states: 'all',
  }) as Array<any>;

  const country = assumptionsData?.country || 'US';

  const getCosts = getCostsMap(assumptionsData.incentivesType, hasTab('lcfs'));

  const cumulativeCosts = cumulative?.map(getCosts);
  const cumulativeFossilCosts = cumulativeCosts?.map((ele) =>
  sum(Object.keys(ele).map((key) => ele[key as keyof typeof ele]?.fossil) || 0)
  );

  const cumulativeElectricCosts = cumulativeCosts?.map((ele) =>
  sum(Object.keys(ele).map((key) => ele[key as keyof typeof ele]?.electric) || 0)
  );

  const lifetimeSavings = sum([
      cumulativeFossilCosts?.at(-1),
      -(cumulativeElectricCosts?.at(-1) || 0),
    ])
  

  // is loading till all status came as true
  const isLoading = !(fuelStatus && vehiclesStatus);

  // it will be true till get inputs and calculations module set
  const missingRequiredInformation = !(calculationsStatus && inputsStatus);

  return {
    status: {
      isLoading,
      missingRequiredInformation,
      isPending: rateEngineStatus,
    },
    data: {
      isOffRoad,
      annualFuelSavings,
      annualFuelSavingsPerMile: annualFuelSavings / (annualMiles || 1),
      annualFuelSavingsPerHour: annualFuelSavings / (annualHours || 1),
      lifetimeSavings,
      vehicleCount,
      totalIncentives: totalIncentives * -1,
      totalEmissions: totalEmissions / (country === 'US' ? 2000 : 1000) || 0,
    },
  };
};
